import { Fragment } from "react";
import { Proyecto } from "../../models/proyecto";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Global from "../../global";

function ProyectoCardSmall({ proyecto }: { proyecto?: Proyecto }) {
  return (
    <Fragment>
      <Grid
        key={proyecto?.id}
        item={true}
        xl={4}
        lg={4}
        md={4}
        sm={6}
        xs={12}
        style={{ textAlign: "center", padding: "10px" }}
      >
        <Avatar
          alt={proyecto?.nombre}
          src={`${Global.URL_FOTOS_PROYECTOS}/${proyecto?.foto}`}
          className="avatar"
        />
        <Typography className="nombreProyecto" variant="h6">
          {proyecto?.nombre}
        </Typography>
      </Grid>
    </Fragment>
  );
}
export default ProyectoCardSmall;
