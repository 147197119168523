export interface APIException {
  success: number;
  message: string;
}

export class APIResponse<T> {
  static loading = new APIResponse<any>({ loading: true });
  static empty = new APIResponse<any>();
  data: T;
  exception: APIException = { success: 0, message: "" };

  constructor({
    data,
  }: {
    data?: any;
    isException?: boolean;
    loading?: boolean;
    loadMore?: boolean;
  } = {}) {
    if (data?.message) {
      this.exception = {
        message: data.message,
        success: data.success,
      };
    }

    this.data = data?.data;
  }
  get hasData(): boolean {
    return !!this.data;
  }

  get hasException(): boolean {
    return !!this.exception;
  }
}
