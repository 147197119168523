import "../../styles/components/proyectos.component.css";
import { Fragment } from "react";
import { Proyecto } from "../../models/proyecto";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import ProyectoCardSmall from "../util/proyecto_card_small";
import Typography from "@material-ui/core/Typography";

function ProyectosComponent({ proyectos }: { proyectos?: Array<Proyecto> }) {
  const listaProyectos = proyectos?.map((proyecto, index) => (
    <ProyectoCardSmall proyecto={proyecto} key={index}></ProyectoCardSmall>
  ));

  return (
    <Fragment>
      <Grid container className="containerProyectosComponent">
        <Grid item={true} xl={12} lg={12} md={12} sm={12} xs={12}>
          <Typography className="tituloProyectosComponent" variant="h4">
            Proyectos
          </Typography>
        </Grid>
        <Grid item={true} xl={12} lg={12} md={12} sm={12} xs={12}>
          <Typography className="subtituloProyectosComponent" variant="h6">
            Conoce acerca de nuestros proyectos
          </Typography>
        </Grid>
        <Grid
          item={true}
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{ textAlign: "center" }}
        >
          <Button
            variant="contained"
            className="btn_amarillo"
            href="/proyectos"
          >
            Ver m&aacute;s
          </Button>
        </Grid>
        <Grid
          item={true}
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{ textAlign: "center" }}
        >
          <Grid container style={{ justifyContent: "center" }}>
            {listaProyectos}
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
}
export default ProyectosComponent;
