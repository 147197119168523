export interface PromocionDataI {
  id?: number;
  imagen?: any;
}

export class Promocion implements PromocionDataI {
  id?: number;
  imagen?: any;

  constructor(data: PromocionDataI) {
    this.id = data.id;
    this.imagen = data.imagen;
  }
}
