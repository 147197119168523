import "../../styles/pages/notfound.css";
import { Empresa } from "../../models/empresa";
import { Fragment } from "react";
import { Grid } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import Facebook from "@material-ui/icons/Facebook";
import Instagram from "@material-ui/icons/Instagram";
import YouTube from "@material-ui/icons/YouTube";

function NotFoundPage({ empresa }: { empresa?: Empresa }) {
  return (
    <Fragment>
      <Grid container>
        <Grid item={true} xl={12} lg={12} md={12} sm={12} xs={12}>
          <Typography variant="h1" className="_404NotFound">
            404
          </Typography>
          <Typography variant="h4" className="subtituloPageNotFound">
            P&Aacute;GINA NO ENCONTRADA
          </Typography>
          <Typography variant="h6" className="textoPageNotFound">
            Es posible que la p&aacute;gina que est&aacute; buscando se haya
            eliminado, <br />
            cambiado de nombre o no esté disponible temporalmente.
          </Typography>{" "}
          <a href="/" className="aPageNotFound">
            <Typography variant="h6" className="btnInicioPageNotFound">
              INICIO
            </Typography>
          </a>
          <div className="divIconsPageNotFound">
            <div className="divIconPageNotFound">
              <a
                href={empresa?.facebook}
                rel="noreferrer"
                target="_blank"
                className="aSiguenos"
              >
                <Facebook className="iconPageNotFound" fontSize="large" />
              </a>
            </div>
            <div className="divIconPageNotFound">
              <a
                href={empresa?.instagram}
                rel="noreferrer"
                target="_blank"
                className="aSiguenos"
              >
                <Instagram className="iconPageNotFound" fontSize="large" />
              </a>
            </div>
            <div className="divIconPageNotFound">
              <a
                href={empresa?.youtube}
                rel="noreferrer"
                target="_blank"
                className="aSiguenos"
              >
                <YouTube className="iconPageNotFound" fontSize="large" />
              </a>
            </div>
          </div>
        </Grid>
      </Grid>
    </Fragment>
  );
}

export default NotFoundPage;
