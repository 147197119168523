import "../../styles/components/nosotros.component.css";
import { Banner } from "../../models/banner";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Global from "../../global";
import Grid from "@material-ui/core/Grid";
import HomeIcon from "@material-ui/icons/Home";
import PeopleIcon from "@material-ui/icons/People";
import React, { Fragment } from "react";
import ThumbsUpDownIcon from "@material-ui/icons/ThumbsUpDown";
import Typography from "@material-ui/core/Typography";

function NosotrosComponent({
  familia1,
  familia2,
}: {
  familia1?: Banner;
  familia2?: Banner;
}) {
  return (
    <Fragment>
      <Grid container className="containerNosotrosComponent">
        <Grid item={true} xl={6} lg={6} md={6} sm={12} xs={12}>
          <Grid container className="containerTextoNosotrosComponent">
            <Grid item={true} xl={12} lg={12} md={12} sm={12} xs={12}>
              <Typography className="subtituloNosotrosComponent" variant="h6">
                Nosotros <br />
                ___
              </Typography>
            </Grid>
            <Grid item={true} xl={12} lg={12} md={12} sm={12} xs={12}>
              <Typography className="tituloNosotrosComponent" variant="h4">
                Propuesta de Valor
              </Typography>
            </Grid>
            <Grid item={true} xl={12} lg={12} md={12} sm={12} xs={12}>
              <Typography className="textoNosotrosComponent" variant="h6">
                Para las familias peruanas, <b>HARAY</b> es la mejor
                opci&oacute;n al brindar servicios integrales en el diseño,
                desarrollo y construcci&oacute;n de complejos habitacionales;
                porque nos
                <b>comprometemos en mejorar la calidad de vida</b> satisfaciendo
                las necesidades habitacionales y comprometidos con el desarrollo
                sustentable del medio ambiente.
              </Typography>
            </Grid>
            <Grid item={true} xl={12} lg={12} md={12} sm={12} xs={12}>
              <Button
                variant="contained"
                className="btn_amarillo"
                href="/nosotros"
              >
                Ver m&aacute;s <ArrowRightIcon />
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item={true} xl={6} lg={6} md={6} sm={12} xs={12}>
          <Grid container className="containerImagenesNosotrosComponent">
            <Grid
              item={true}
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
              className="containerImagenChicaNosotrosComponent"
            >
              <img
                src={`${Global.URL_IMAGENES_BANNERS}/${familia2?.url ?? "loading.gif"}`}
                alt="Inmobiliaria HARAY"
                title="Inmobiliaria HARAY"
                width="250px"
                style={{
                  borderRadius: "15px",
                  marginRight: "-50px",
                  marginBottom: "-20px",
                  zIndex: 1,
                }}
              />
            </Grid>
            <Grid item={true} xl={6} lg={6} md={6} sm={12} xs={12}>
              <img
                src={`${Global.URL_IMAGENES_BANNERS}/${familia1?.url ?? "loading.gif"}`}
                alt="Inmobiliaria HARAY"
                title="Inmobiliaria HARAY"
                width="300px"
                height="500px"
                style={{ borderRadius: "15px", objectFit: "cover" }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item={true} xl={12} lg={12} md={12} sm={12} xs={12}>
          <Grid container className="containerIconsNosotrosComponent">
            <Grid item={true} xl={4} lg={4} md={4} sm={12} xs={12}>
              <Card className="cardNosotrosComponent">
                <CardHeader
                  avatar={
                    <Avatar className="avatarNosotrosComponent">
                      <PeopleIcon className="iconNosotrosComponent" />
                    </Avatar>
                  }
                  title={
                    <Typography
                      className="preguntaNosotrosComponent"
                      variant="h5"
                    >
                      ¿Qui&eacute;nes somos?
                    </Typography>
                  }
                  subheader={
                    <Typography
                      className="respuestaNosotrosComponent"
                      variant="h6"
                    >
                      <b>Somos</b> una empresa con m&aacute;s de 25 años de
                      experiencia
                    </Typography>
                  }
                  className="cardHeaderNosotrosComponent"
                />
              </Card>
            </Grid>
            <Grid item={true} xl={4} lg={4} md={4} sm={12} xs={12}>
              <Card className="cardNosotrosComponent">
                <CardHeader
                  avatar={
                    <Avatar className="avatarNosotrosComponent">
                      <HomeIcon className="iconNosotrosComponent" />
                    </Avatar>
                  }
                  title={
                    <Typography
                      className="preguntaNosotrosComponent"
                      variant="h5"
                    >
                      ¿Qu&eacute; ofrecemos?
                    </Typography>
                  }
                  subheader={
                    <Typography
                      className="respuestaNosotrosComponent"
                      variant="h6"
                    >
                      <b>Ofrecemos</b> mejorar tu calidad de vida y trabajo
                    </Typography>
                  }
                  className="cardHeaderNosotrosComponent"
                />
              </Card>
            </Grid>
            <Grid item={true} xl={4} lg={4} md={4} sm={12} xs={12}>
              <Card className="cardNosotrosComponent">
                <CardHeader
                  avatar={
                    <Avatar className="avatarNosotrosComponent">
                      <ThumbsUpDownIcon className="iconNosotrosComponent" />
                    </Avatar>
                  }
                  title={
                    <Typography
                      className="preguntaNosotrosComponent"
                      variant="h5"
                    >
                      ¿Qu&eacute; brindamos?
                    </Typography>
                  }
                  subheader={
                    <Typography
                      className="respuestaNosotrosComponent"
                      variant="h6"
                    >
                      <b>Brindamos</b> facilidades de pago con el Fondo Mi
                      Vivienda.
                    </Typography>
                  }
                  className="cardHeaderNosotrosComponent"
                />
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
}
export default NosotrosComponent;
