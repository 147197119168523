import "../../styles/pages/proyectos.page.css";
import { Banner } from "../../models/banner";
import { Empresa } from "../../models/empresa";
import { Fragment } from "react";
import { Promocion } from "../../models/promocion";
import { Proyecto } from "../../models/proyecto";
import AppBarComponent from "../components/appbar.component";
import FooterComponent from "../components/footer.component";
import Global from "../../global";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListAltIcon from "@material-ui/icons/ListAlt";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import PromocionesProyectosComponent from "../components/promocionesProyectos.component";
import ProyectoCardBig from "../util/proyecto_card_big";
import Typography from "@material-ui/core/Typography";

function ProyectosPage({
  empresa,
  banner,
  logoTechoPropio,
  logoMiVivienda,
  logoLibro,
  listaProyectosApi,
  listaPromocionesApi,
}: {
  empresa?: Empresa;
  banner?: Banner;
  logoTechoPropio?: Banner;
  logoMiVivienda?: Banner;
  logoLibro?: Banner;
  listaProyectosApi?: Array<Proyecto>;
  listaPromocionesApi?: Array<Promocion>;
}) {
  const listaProyectos = listaProyectosApi?.map((proyecto, index) => (
    <ProyectoCardBig key={index} proyecto={proyecto}></ProyectoCardBig>
  ));

  return (
    <Fragment>
      <Grid container>
        <Grid item={true} xl={12} lg={12} md={12} sm={12} xs={12}>
          <div
            style={{
              backgroundImage: `url(${Global.URL_IMAGENES_BANNERS}/${banner?.url ?? "loading.gif"})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              height: "80vh",
            }}
          >
            <AppBarComponent celular={empresa?.celular}></AppBarComponent>

            <Grid container>
              <Grid
                item={true}
                xl={5}
                lg={5}
                md={5}
                sm={12}
                xs={12}
                className="gridTituloProyectosPage"
              >
                <Typography variant="h3" className="tituloProyectosPage">
                  __
                  <br />
                  Proyectos
                </Typography>
              </Grid>
              <Grid
                item={true}
                xl={7}
                lg={7}
                md={7}
                sm={12}
                xs={12}
                className="gridSubtituloProyectosPage"
              >
                <Typography variant="h6" className="subtituloProyectosPage">
                  Contamos con profesionales y personal de amplia experiencia en
                  el ramo. Nuestro valor agregado es la mejor atenci&oacute;n
                  personalizada, el mejor presupuesto y tiempo con un buen
                  control de obra.
                </Typography>
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid
          item={true}
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className="segundoGridProyectosPage"
        >
          <Grid container>
            <Grid item={true} xl={8} lg={8} md={8} sm={12} xs={12}>
              <Typography variant="h6" className="bridamosTextoProyectosPage">
                Brindamos facilidades de pago con el Fondo Mi Vivienda, por lo
                que podrás acceder a los bonos que otorga el estado:
              </Typography>
              <List>
                <ListItem>
                  <ListItemAvatar style={{ textAlign: "center" }}>
                    <ListAltIcon style={{ color: "rgb(255 170 0)" }} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Typography
                        className="benficioProyectosPage"
                        variant="h6"
                      >
                        Bono Techo Propio
                      </Typography>
                    }
                  />
                </ListItem>
                {/* <ListItem>
                  <ListItemAvatar style={{ textAlign: "center" }}>
                    <ListAltIcon style={{ color: "rgb(255 170 0)" }} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Typography
                        className="benficioProyectosPage"
                        variant="h6"
                      >
                        Bono Nuevo Cr&eacute;dito Mi Vivienda
                      </Typography>
                    }
                  />
                </ListItem> */}
              </List>
              <Typography variant="h6" className="empresaProyectosPage">
                ___ <br /> {empresa?.nombreComercial}
              </Typography>
            </Grid>
            <Grid
              item={true}
              xl={4}
              lg={4}
              md={4}
              sm={12}
              xs={12}
              className="gridImagenesProyectosPage"
            >
              <img
                src={`${Global.URL_IMAGENES_BANNERS}/${
                  logoTechoPropio?.url ?? "loading.gif"
                }`}
                alt="Techo Propio"
                title="Techo propio"
                className="imagenTechoPropioProyectosPage"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item={true} xl={12} lg={12} md={12} sm={12} xs={12}>
          <Grid container className="containerProyectos">
            {listaProyectos}
          </Grid>
        </Grid>
        <Grid item={true} xl={12} lg={12} md={12} sm={12} xs={12}>
          <Grid container className="containerProyectos">
            <PromocionesProyectosComponent
              promociones={listaPromocionesApi}
            ></PromocionesProyectosComponent>
          </Grid>
        </Grid>
        <Grid item={true} xl={12} lg={12} md={12} sm={12} xs={12}>
          <FooterComponent
            empresa={empresa}
            libroReclamaciones={logoLibro}
          ></FooterComponent>
        </Grid>
      </Grid>
    </Fragment>
  );
}
export default ProyectosPage;
