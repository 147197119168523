export interface ProyectoDataI {
  id?: number;
  nombre?: string;
  foto?: any;
  descripcion?: string;
  direccion?: string;
  ubicacion?: string;
  facebook?: string;
  instagram?: string;
  celular?: string;
}

export class Proyecto implements ProyectoDataI {
  id?: number;
  nombre?: string;
  foto?: any;
  descripcion?: string;
  direccion?: string;
  ubicacion?: string;
  facebook?: string;
  instagram?: string;
  celular?: string;

  constructor(data: ProyectoDataI) {
    this.id = data.id;
    this.nombre = data.nombre;
    this.foto = data.foto;
    this.descripcion = data.descripcion;
    this.direccion = data.direccion;
    this.ubicacion = data.ubicacion;
    this.facebook = data.facebook;
    this.instagram = data.instagram;
    this.celular = data.celular;
  }
}
