import "../../styles/components/footer.component.css";
import { Banner } from "../../models/banner";
import { Empresa } from "../../models/empresa";
import { Fragment } from "react";
import FacebookIcon from "@material-ui/icons/Facebook";
import Global from "../../global";
import Grid from "@material-ui/core/Grid";
import InstagramIcon from "@material-ui/icons/Instagram";
import logo from "../../assets/logoBlanco.svg";
import MailIcon from "@material-ui/icons/Mail";
import PhoneIcon from "@material-ui/icons/Phone";
import RoomIcon from "@material-ui/icons/Room";
import Typography from "@material-ui/core/Typography";

function FooterComponent({
  empresa,
  libroReclamaciones,
}: {
  empresa?: Empresa;
  libroReclamaciones?: Banner;
}) {
  return (
    <Fragment>
      <Grid container className="containerFooterComponent">
        <Grid item={true} xl={4} lg={4} md={6} sm={12} xs={12}>
          <img
            src={logo}
            alt="HARAY"
            title="HARAY"
            className="logoFooterComponent"
          />
          <a
            className="aFooterComponent"
            href="https://www.google.com/maps/place/HARAY+Inmobiliaria/@-8.1214979,-79.0354914,19z/data=!4m13!1m7!3m6!1s0x91ad3d70eabfc8b5:0x8bfa3f9ed62f90cb!2sSta.+Teresa+de+Jes%C3%BAs,+Trujillo+13008!3b1!8m2!3d-8.1221569!4d-79.0341902!3m4!1s0x91ad3dae97912023:0x973b22633e29499f!8m2!3d-8.1213271!4d-79.0353278"
            target="_blank"
            rel="noreferrer"
          >
            <Typography className="datosFooterComponent" variant="h6">
              <RoomIcon className="iconsFooterComponent" />
              {empresa?.direccion}
            </Typography>
          </a>
          <Typography className="datosFooterComponent" variant="h6">
            <PhoneIcon className="iconsFooterComponent" />
            {empresa?.celular}
          </Typography>
          <Typography className="datosFooterComponent" variant="h6">
            <MailIcon className="iconsFooterComponent" />
            {empresa?.correo}
          </Typography>
        </Grid>
        <Grid
          item={true}
          xl={4}
          lg={4}
          md={6}
          sm={12}
          xs={12}
          className="containerCentroFooterComponent"
        >
          <Typography className="tituloFooterComponent" variant="h5">
            S&iacute;guenos en <br />
            nuestras redes
            <br />
            sociales <br /> ___
          </Typography>
          <a
            className="aFooterComponent"
            href={empresa?.facebook}
            target="_blank"
            rel="noreferrer"
          >
            <Typography className="datosFooterComponent" variant="h6">
              <FacebookIcon className="iconsFooterComponent" />
              /harayinmobiliaria
            </Typography>
          </a>
          <a
            className="aFooterComponent"
            href={empresa?.instagram}
            target="_blank"
            rel="noreferrer"
          >
            <Typography className="datosFooterComponent" variant="h6">
              <InstagramIcon className="iconsFooterComponent" />
              @harayinmobiliaria
            </Typography>
          </a>
        </Grid>
        <Grid
          item={true}
          xl={2}
          lg={2}
          md={6}
          sm={12}
          xs={12}
          className="containerDerechaFooterComponent"
        >
          <Typography className="tituloFooterComponent" variant="h5">
            Enlaces <br /> ___
          </Typography>
          <a className="aFooterComponent" href="/" rel="noreferrer">
            <Typography className="enlacesFooterComponent" variant="h6">
              Inicio
            </Typography>
          </a>
          <a className="aFooterComponent" href="/nosotros" rel="noreferrer">
            <Typography className="enlacesFooterComponent" variant="h6">
              Nosotros
            </Typography>
          </a>
          <a className="aFooterComponent" href="/asesores" rel="noreferrer">
            <Typography className="enlacesFooterComponent" variant="h6">
              Asesores
            </Typography>
          </a>
          <a className="aFooterComponent" href="/proyectos" rel="noreferrer">
            <Typography className="enlacesFooterComponent" variant="h6">
              Proyectos
            </Typography>
          </a>
          <a className="aFooterComponent" href="/contactanos" rel="noreferrer">
            <Typography className="enlacesFooterComponent" variant="h6">
              Cont&aacute;ctanos
            </Typography>
          </a>
        </Grid>
        <Grid
          item={true}
          xl={2}
          lg={2}
          md={6}
          sm={12}
          xs={12}
          className="containerLibroFooterComponent"
        >
          <div>
            <Typography className="libroTextoFooterComponent" variant="h6">
              Libro de reclamaciones
            </Typography>
            <img
              src={`${Global.URL_IMAGENES_BANNERS}/${
                libroReclamaciones?.url ?? "loading.gif" ?? "loading.gif"
              }`}
              alt="HARAY"
              title="HARAY"
              className="libroFooterComponent"
            />
            <Typography className="leyFooterComponent" variant="h6">
              Ley N°29571. C&oacute;digo de Protecci&oacute;n y Defensa del
              Consumidor
            </Typography>
          </div>
        </Grid>
      </Grid>
    </Fragment>
  );
}
export default FooterComponent;
