import axios from "axios";
import Global from "../global";
import { APIResponse } from "../models/api_response";

interface APIConfig {
  body?: any;
  query?: any;
  auth?: boolean;
}

export class API {
  private baseUrl: string = Global.URL_API;
  constructor(baseUrl: string) {
    this.baseUrl += baseUrl;
  }

  protected async get(
    url: string,
    config?: APIConfig
  ): Promise<APIResponse<any>> {
    const { query = {} } = config || {};
    const headers: any = {
      "Content-Type": "application/json",
    };
    let strQuery = "";
    const keys = Object.keys(query);
    keys.forEach((element, index) => {
      if (index === 0) {
        strQuery = `?${element}=${query[element]}`;
      } else {
        strQuery += `&${element}=${query[element]}`;
      }
    });

    try {
      const result = await axios.get(`${this.baseUrl}${url}${strQuery}`, {
        headers: headers,
      });
      return new APIResponse<any>({ data: result.data });
    } catch (e: any) {
      const result = e.response.data;
      return new APIResponse<any>({ data: result, isException: true });
    }
  }

  protected async post(
    url: string,
    config?: APIConfig
  ): Promise<APIResponse<any>> {
    const { body = {}, query = {} } = config || {};
    const headers: any = { "Content-Type": "application/json" };

    let strQuery = "";
    const keys = Object.keys(query);
    keys.forEach((element, index) => {
      if (index === 0) {
        strQuery = `?${element}=${query[element]}`;
      } else {
        strQuery += `&${element}=${query[element]}`;
      }
    });

    try {
      const result = await axios.post(
        `${this.baseUrl}${url}${strQuery}`,
        body,
        {
          headers: headers,
        }
      );
      return new APIResponse<any>({ data: result.data });
    } catch (e: any) {
      const result = e.response.data;
      return new APIResponse<any>({ data: result, isException: true });
    }
  }
}
