import { APIAsesor } from "../../api/api_asesor";
import { APIBanner } from "../../api/api_banner";
import { APIEmpresa } from "../../api/api_empresa";
import { APIPromocion } from "../../api/api_promocion";
import { APIProyecto } from "../../api/api_proyecto";
import { Asesor } from "../../models/asesor";
import { Banner } from "../../models/banner";
import { BrowserRouter as Router } from "react-router-dom";
import { Empresa } from "../../models/empresa";
import { Promocion } from "../../models/promocion";
import { Proyecto } from "../../models/proyecto";
import { Route } from "react-router-dom";
import { Switch } from "react-router-dom";
import { useCallback } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import AsesorContext from "../../providers/asesor-provider";
import AsesoresPage from "./asesores.page";
import BannerContext from "../../providers/banner-provider";
import ContactanosPage from "./contactanos.page";
import EmpresaContext from "../../providers/empresa-provider";
import InicioPage from "./inicio.page";
import NosotrosPage from "./nosotros.page";
import NotFoundPage from "./notfound.page";
import PromocionContext from "../../providers/promocion-provider";
import ProyectoContext from "../../providers/proyecto-provider";
import ProyectosPage from "./proyectos.page";

function AppPage() {
  const apiEmpresa = useContext<APIEmpresa>(EmpresaContext);
  const apiBanner = useContext<APIBanner>(BannerContext);
  const apiAsesor = useContext<APIAsesor>(AsesorContext);
  const apiProyecto = useContext<APIProyecto>(ProyectoContext);
  const apiPromocion = useContext<APIPromocion>(PromocionContext);
  const [empresa, setEmpresa] = useState<Empresa>(new Empresa({}));
  const [banners, setBanners] = useState<Array<Banner>>([]);
  const [asesores, setAsesores] = useState<Array<Asesor>>([]);
  const [proyectos, setProyectos] = useState<Array<Proyecto>>([]);
  const [promociones, setPromociones] = useState<Array<Promocion>>([]);

  const readDataEmpresa = useCallback(async () => {
    let result = await apiEmpresa.getData();
    if (result.hasData) {
      setEmpresa(result.data);
    }
  }, [apiEmpresa]);

  const getBanners = useCallback(async () => {
    let result = await apiBanner.getData();
    if (result.hasData) {
      setBanners(result.data);
    }
  }, [apiBanner]);

  const getAsesores = useCallback(async () => {
    let result = await apiAsesor.getData();
    if (result.hasData) {
      setAsesores(result.data);
    }
  }, [apiAsesor]);

  const getProyectos = useCallback(async () => {
    let result = await apiProyecto.getData();
    if (result.hasData) {
      setProyectos(result.data);
    }
  }, [apiProyecto]);

  const getPromociones = useCallback(async () => {
    let result = await apiPromocion.getData();
    if (result.hasData) {
      setPromociones(result.data);
    }
  }, [apiPromocion]);

  useEffect(() => {
    readDataEmpresa();
    getBanners();
    getAsesores();
    getProyectos();
    getPromociones();
  }, [readDataEmpresa, getBanners, getAsesores, getProyectos, getPromociones]);

  return (
    <Router>
      <Switch>
        <Route
          exact={true}
          path="/"
          render={() => {
            return (
              <InicioPage
                empresa={empresa}
                banner={banners[0]}
                familia1={banners[5]}
                familia2={banners[6]}
                logoLibro={banners[12]}
                listaProyectosApi={proyectos}
                listaPromocionesApi={promociones}
              />
            );
          }}
        ></Route>
        <Route
          exact={true}
          path="/nosotros"
          render={() => {
            return (
              <NosotrosPage
                empresa={empresa}
                banner={banners[1]}
                bannerCasa={banners[8]}
                logoLibro={banners[12]}
                listaProyectosApi={proyectos}
              />
            );
          }}
        ></Route>
        <Route
          exact={true}
          path="/asesores"
          render={() => {
            return (
              <AsesoresPage
                empresa={empresa}
                banner={banners[2]}
                bannerFamilia={banners[9]}
                logoLibro={banners[12]}
                listaAsesoresApi={asesores}
              />
            );
          }}
        ></Route>
        <Route
          exact={true}
          path="/proyectos"
          render={() => {
            return (
              <ProyectosPage
                empresa={empresa}
                banner={banners[3]}
                logoTechoPropio={banners[10]}
                logoMiVivienda={banners[11]}
                logoLibro={banners[12]}
                listaProyectosApi={proyectos}
                listaPromocionesApi={promociones}
              />
            );
          }}
        ></Route>
        <Route
          exact={true}
          path="/contactanos"
          render={() => {
            return (
              <ContactanosPage
                empresa={empresa}
                banner={banners[4]}
                logoLibro={banners[12]}
              />
            );
          }}
        ></Route>
        <Route
          exact={true}
          render={() => {
            return <NotFoundPage empresa={empresa} />;
          }}
        ></Route>
      </Switch>
    </Router>
  );
}

export default AppPage;
