export interface EmpresaDataI {
  id?: number;
  ruc?: string;
  razonSocial?: string;
  nombreComercial?: string;
  direccion?: string;
  correo?: string;
  celular?: string;
  facebook?: string;
  instagram?: string;
  youtube?: string;
  mision?: string;
  vision?: string;
  somos?: string;
}

export class Empresa implements EmpresaDataI {
  id?: number;
  ruc?: string;
  razonSocial?: string;
  nombreComercial?: string;
  direccion?: string;
  correo?: string;
  celular?: string;
  facebook?: string;
  instagram?: string;
  youtube?: string;
  mision?: string;
  vision?: string;
  somos?: string;

  constructor(data: EmpresaDataI) {
    this.id = data.id;
    this.ruc = data.ruc;
    this.razonSocial = data.razonSocial;
    this.nombreComercial = data.nombreComercial;
    this.direccion = data.direccion;
    this.correo = data.correo;
    this.celular = data.celular;
    this.facebook = data.facebook;
    this.instagram = data.instagram;
    this.youtube = data.youtube;
    this.mision = data.mision;
    this.vision = data.vision;
    this.somos = data.somos;
  }
}
