import { API } from "./api";

export class APIMail extends API {
  constructor() {
    super("/sendMail/");
  }

  async sendMail(body: any, origen: string) {
    const subject: string = body["subject"];
    const nombres: string = body["nombres"];
    const apellidos: string = body["apellidos"];
    const celular: string = body["celular"];
    const correo: string = body["email"];
    const dni: string = body["dni"];
    const proyecto: string = body["subject"];
    const mensaje: string = body["message"];
    return await this.get(
      `${subject}&${nombres}&${apellidos}&${celular}&${correo}&${dni}&${proyecto}&${mensaje}&${origen}`
    );
  }
}
