import "../../styles/pages/nosotros.page.css";
import { Banner } from "../../models/banner";
import { Empresa } from "../../models/empresa";
import { Fragment } from "react";
import { Proyecto } from "../../models/proyecto";
import AppBarComponent from "../components/appbar.component";
import FooterComponent from "../components/footer.component";
import Global from "../../global";
import Grid from "@material-ui/core/Grid";
import logoVertical from "../../assets/logoVertical.png";
import ProyectoCardMedium from "../util/proyecto_card_medium";
import Typography from "@material-ui/core/Typography";

function NosotrosPage({
  empresa,
  banner,
  bannerCasa,
  logoLibro,
  listaProyectosApi,
}: {
  empresa?: Empresa;
  banner?: Banner;
  bannerCasa?: Banner;
  logoLibro?: Banner;
  listaProyectosApi?: Array<Proyecto>;
}) {
  const listaProyectos = listaProyectosApi?.map((proyecto, index) => (
    <ProyectoCardMedium proyecto={proyecto} key={index}></ProyectoCardMedium>
  ));
  return (
    <Fragment>
      <Grid container>
        <Grid item={true} xl={12} lg={12} md={12} sm={12} xs={12}>
          <div
            style={{
              backgroundImage: `url(${Global.URL_IMAGENES_BANNERS}/${
                banner?.url ?? "loading.gif"
              })`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          >
            <AppBarComponent celular={empresa?.celular}></AppBarComponent>
            <Grid container>
              <Grid
                item={true}
                xl={5}
                lg={5}
                md={5}
                sm={12}
                xs={12}
                className="gridTituloNosotrosPage"
              >
                <Typography className="titleNosotrosPage" variant="h3">
                  __
                  <br />
                  Nosotros
                </Typography>
              </Grid>
              <Grid
                item={true}
                xl={7}
                lg={7}
                md={7}
                sm={12}
                xs={12}
                className="gridSomosNosotrosPage"
              >
                <Typography className="subtitleNosotrosPage" variant="h6">
                  {empresa?.somos?.substring(0, 123)}
                </Typography>
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid item={true} xl={12} lg={12} md={12} sm={12} xs={12}>
          <Grid container>
            <Grid item={true} xl={12} lg={12} md={12} sm={12} xs={12}>
              <Typography className="preguntaNosotrosPage" variant="h6">
                ¿Qui&eacute;nes somos? <br /> ___
              </Typography>
            </Grid>
            <Grid item={true} xl={12} lg={12} md={12} sm={12} xs={12}>
              <Typography className="titleAzulNosotrosPage" variant="h4">
                Inmobiliaria HARAY
              </Typography>
            </Grid>
            <Grid item={true} xl={12} lg={12} md={12} sm={12} xs={12}>
              <Typography className="somosNosotrosPage" variant="h6">
                {empresa?.somos}
              </Typography>
            </Grid>
            <Grid
              item={true}
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              style={{ textAlign: "center", padding: "50px" }}
            >
              <img
                src={`${Global.URL_IMAGENES_BANNERS}/${
                  bannerCasa?.url ?? "loading.gif"
                }`}
                alt="Inmobiliria HARAY"
                title="Inmobiliaria HARAY"
                className="slideNosotrosNosotrosPage"
              />
            </Grid>
            <Grid item={true} xl={12} lg={12} md={12} sm={12} xs={12}>
              <Grid container>
                <Grid
                  item={true}
                  xl={4}
                  lg={4}
                  md={4}
                  sm={12}
                  xs={12}
                  className="gridMisionVisionTituloNosotrosPage"
                >
                  <Typography
                    className="misionVisionTituloNosotrosPage"
                    variant="h4"
                  >
                    ___ <br />
                    Misi&oacute;n y Visi&oacute;n
                  </Typography>
                </Grid>
                <Grid
                  item={true}
                  xl={8}
                  lg={8}
                  md={8}
                  sm={12}
                  xs={12}
                  className="gridMisionVisionNosotrosPage"
                >
                  <Typography className="misionNosotrosPage" variant="h6">
                    {empresa?.mision}
                  </Typography>
                  <Typography className="visionNosotrosPage" variant="h6">
                    {empresa?.vision}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item={true} xl={12} lg={12} md={12} sm={12} xs={12}>
              <Grid container>
                <Grid
                  item={true}
                  xl={4}
                  lg={4}
                  md={4}
                  sm={12}
                  xs={12}
                  className="gridMisionVisionTituloNosotrosPage"
                >
                  <Typography
                    className="misionVisionTituloNosotrosPage"
                    variant="h4"
                  >
                    ___ <br />
                    ¿Qu&eacute; es lo que ofrecemos?
                  </Typography>
                </Grid>
                <Grid
                  item={true}
                  xl={8}
                  lg={8}
                  md={8}
                  sm={12}
                  xs={12}
                  className="gridMisionVisionNosotrosPage"
                >
                  <Typography className="misionNosotrosPage" variant="h6">
                    Facilitamos por medio del bono Techo propio y Mi Vivienda la
                    accesibilidad a nuestros clientes en la compra de una
                    vivienda propia
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item={true} xl={12} lg={12} md={12} sm={12} xs={12}>
              <Grid container className="gridPropuestaValorNosotrosPage">
                <Grid
                  item={true}
                  xl={4}
                  lg={4}
                  md={4}
                  sm={12}
                  xs={12}
                  className="gridMisionVisionTituloNosotrosPage"
                >
                  <img
                    src={logoVertical}
                    alt="Inmobiliria HARAY"
                    title="Inmobiliaria HARAY"
                    className={"logoAzulVerticalNosotrosPage"}
                  />
                </Grid>
                <Grid
                  item={true}
                  xl={8}
                  lg={8}
                  md={8}
                  sm={12}
                  xs={12}
                  className="gridMisionVisionNosotrosPage"
                >
                  <Typography
                    className="misionVisionTituloNosotrosPage"
                    variant="h4"
                  >
                    Propuesta de valor
                  </Typography>
                  <Typography className="misionNosotrosPage" variant="h6">
                    Para las familias peruanas, <b>HARAY</b> es la mejor
                    opci&oacute;n al brindar servicios integrales en el diseño,
                    desarrollo y construcción de complejos habitacionales;
                    porque nos
                    <b> comprometemos en mejorar la calidad de vida</b>
                    satisfaciendo las necesidades habitacionales y comprometidos
                    con el desarrollo sustentable del medio ambiente.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item={true} xl={12} lg={12} md={12} sm={12} xs={12}>
              <Typography
                className="nuestrosProyectosTituloNosotrosPage"
                variant="h4"
              >
                Nuestros Proyectos
              </Typography>
            </Grid>
            <Grid item={true} xl={12} lg={12} md={12} sm={12} xs={12}>
              <Grid container>{listaProyectos}</Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item={true} xl={12} lg={12} md={12} sm={12} xs={12}>
          <FooterComponent
            empresa={empresa}
            libroReclamaciones={logoLibro}
          ></FooterComponent>
        </Grid>
      </Grid>
    </Fragment>
  );
}
export default NosotrosPage;
