import "../../styles/components/promocionesInicio.component.css";
import { Promocion } from "../../models/promocion";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import Button from "@material-ui/core/Button";
import Global from "../../global";
import Grid from "@material-ui/core/Grid";
import { Fragment } from "react";
import Typography from "@material-ui/core/Typography";

function PromocionesInicioComponent({
  promocionA,
  promocionB,
}: {
  promocionA?: Promocion;
  promocionB?: Promocion;
}) {
  return promocionA && promocionB ? (
    <Fragment>
      <Grid container className="containerPromocionesInicioComponent">
        <Grid item={true} xl={4} lg={4} md={4} sm={12} xs={12}>
          <Grid container className="containerTextoPromocionesInicioComponent">
            <Grid item={true} xl={12} lg={12} md={12} sm={12} xs={12}>
              <Typography
                className="subtituloPromocionesInicioComponent"
                variant="h6"
              >
                Proyectos <br /> ___
              </Typography>
            </Grid>
            <Grid item={true} xl={12} lg={12} md={12} sm={12} xs={12}>
              <Typography
                className="tituloPromocionesInicioComponent"
                variant="h4"
              >
                Nuestras <br /> Promociones
              </Typography>
            </Grid>
            <Grid
              item={true}
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className="gridBotonAmarilloPromocionesInicioComponent"
            >
              <Button
                variant="contained"
                className="btn_amarillo"
                href="/proyectos"
              >
                Ver m&aacute;s <ArrowRightIcon />
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item={true}
          xl={4}
          lg={4}
          md={4}
          sm={6}
          xs={12}
          style={{ textAlign: "center" }}
        >
          <img
            src={`${Global.URL_IMAGENES_PROMOCIONES}/${
              promocionA?.imagen ?? "loading.gif"
            }`}
            alt="Inmobiliaria HARAY"
            title="Inmobiliaria HARAY"
            className="ofertaPromocionesInicioComponent"
          />
        </Grid>
        <Grid
          item={true}
          xl={4}
          lg={4}
          md={4}
          sm={6}
          xs={12}
          style={{ textAlign: "center" }}
        >
          <img
            src={`${Global.URL_IMAGENES_PROMOCIONES}/${
              promocionB?.imagen ?? "loading.gif"
            }`}
            alt="Inmobiliaria HARAY"
            title="Inmobiliaria HARAY"
            className="ofertaPromocionesInicioComponent"
          />
        </Grid>
      </Grid>
    </Fragment>
  ) : (
    <div></div>
  );
}
export default PromocionesInicioComponent;
