import "../../styles/components/inicio.component.css";
import { Empresa } from "../../models/empresa";
import { Fragment } from "react";
import Button from "@material-ui/core/Button";
import FacebookIcon from "@material-ui/icons/Facebook";
import Grid from "@material-ui/core/Grid";
import InstagramIcon from "@material-ui/icons/Instagram";
import RoomIcon from "@material-ui/icons/Room";
import Typography from "@material-ui/core/Typography";

function InicioComponent({ empresa }: { empresa?: Empresa }) {
  return (
    <Fragment>
      <Grid container className="containerInicioComponent">
        <Grid item={true} xl={6} lg={6} md={6} sm={12} xs={12}>
          <Grid container>
            <Grid
              item={true}
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className="gridTitle1InicioComponent"
            >
              <Typography className="title1InicioComponent" variant="h5">
                {empresa?.nombreComercial}
                <br />
                ___
              </Typography>
            </Grid>
            <Grid
              item={true}
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className="gridTitle2InicioComponent"
            >
              <Typography className="title2InicioComponent" variant="h2">
                Vive tu sueño <br />
                hecho realidad
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item={true}
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          className="containerSomosInicioComponent"
        >
          <Typography className="somosInicioComponent" variant="h6">
            {empresa?.somos}
          </Typography>
          <Button variant="contained" className="btn_amarillo" href="/nosotros">
            Ver m&aacute;s
          </Button>
        </Grid>
        <Grid
          item={true}
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className="containerBottomInicioComponent"
        >
          <hr />
          <Grid container>
            <Grid
              item={true}
              xl={6}
              lg={6}
              md={6}
              sm={2}
              xs={12}
              className="gridIconsInicioComponent"
            >
              <a href={empresa?.facebook} target="_blank" rel="noreferrer">
                <FacebookIcon className="facebookIconInicioComponent" />
              </a>
              <a href={empresa?.instagram} target="_blank" rel="noreferrer">
                <InstagramIcon className="instagramIconInicioComponent" />
              </a>
            </Grid>
            <Grid
              item={true}
              xl={6}
              lg={6}
              md={6}
              sm={10}
              xs={12}
              className="gridDirectionInicioComponent"
            >
              <a
                href="https://www.google.com/maps/place/HARAY+Inmobiliaria/@-8.1214979,-79.0354914,19z/data=!4m13!1m7!3m6!1s0x91ad3d70eabfc8b5:0x8bfa3f9ed62f90cb!2sSta.+Teresa+de+Jes%C3%BAs,+Trujillo+13008!3b1!8m2!3d-8.1221569!4d-79.0341902!3m4!1s0x91ad3dae97912023:0x973b22633e29499f!8m2!3d-8.1213271!4d-79.0353278"
                target="_blank"
                rel="noreferrer"
              >
                <Typography className="direccionInicioComponent" variant="h6">
                  <RoomIcon className="roomIconInicioComponent" />
                  {empresa?.direccion}
                </Typography>
              </a>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
}
export default InicioComponent;
