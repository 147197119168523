import { APIResponse } from "../models/api_response";
import { Banner } from "../models/banner";
import { API } from "./api";

export class APIBanner extends API {
  constructor() {
    super("/banners/");
  }

  async getData(): Promise<APIResponse<Array<Banner>>> {
    const result = await this.get("", {
      body: {},
    });
    if (result.exception.message !== "") {
      return result;
    }
    const banners: Banner[] = [];
    result.data?.forEach((banner: Banner) => {
      banners.push(new Banner(banner));
    });
    result.data = banners;
    return result as any;
  }
}
