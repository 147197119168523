export interface AsesorDataI {
  id?: number;
  nombre?: string;
  cargo?: string;
  foto?: any;
  email?: string;
  celular?: string;
  proyectoAsignado?: string;
}

export class Asesor implements AsesorDataI {
  id?: number;
  nombre?: string;
  cargo?: string;
  foto?: any;
  email?: string;
  celular?: string;
  proyectoAsignado?: string;

  constructor(data: AsesorDataI) {
    this.id = data.id;
    this.nombre = data.nombre;
    this.cargo = data.cargo;
    this.foto = data.foto;
    this.email = data.email;
    this.celular = data.celular;
    this.proyectoAsignado = data.proyectoAsignado;
  }
}
