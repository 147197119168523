export default class Global {
  // static URL_API = "http://10.2.5.87/haray_api/index.php";
  static URL_API = "https://harayapi.haray.com.pe/index.php";
  static URL_IMAGENES_BANNERS =
    "https://haraypanel.haray.com.pe/images/page/banners";
  static URL_FOTOS_ASESORES =
    "https://haraypanel.haray.com.pe/images/page/asesores";
  static URL_FOTOS_PROYECTOS =
    "https://haraypanel.haray.com.pe/images/page/proyectos";
  static URL_IMAGENES_PROMOCIONES =
    "https://haraypanel.haray.com.pe/images/page/promociones";
}
