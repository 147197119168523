import React from "react";
import ReactDOM from "react-dom";
import AppPage from "./ui/pages/app.page";
import "./styles/index.css";

ReactDOM.render(
  // <React.StrictMode>
  <AppPage />,
  // </React.StrictMode>,
  document.getElementById("root")
);
