import { Asesor } from "../../models/asesor";
import { Fragment } from "react";
import Global from "../../global";
import Grid from "@material-ui/core/Grid";
import MailIcon from "@material-ui/icons/Mail";
import PhoneIcon from "@material-ui/icons/Phone";
import Typography from "@material-ui/core/Typography";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";

function AsesorCard({ asesor }: { asesor?: Asesor }) {
  return (
    <Fragment>
      <Grid container className="containerAsesorAsesoresPage" key={asesor?.id}>
        <Grid
          item={true}
          xl={5}
          lg={5}
          md={5}
          sm={12}
          xs={12}
          className="gridFotoAsesorAsesoresPage"
        >
          <img
            src={`${Global.URL_FOTOS_ASESORES}/${asesor?.foto}`}
            alt={asesor?.nombre}
            title={asesor?.nombre}
            className="fotoAsesorAsesoresPage"
          />
        </Grid>
        <Grid
          item={true}
          xl={7}
          lg={7}
          md={7}
          sm={12}
          xs={12}
          className="gridDatosAsesorAsesoresPage"
        >
          <Typography className="nombreAsesorAsesoresPage" variant="h4">
            {asesor?.nombre}
          </Typography>
          <Typography className="cargoAsesorAsesoresPage" variant="h6">
            {asesor?.cargo}
          </Typography>
          <Typography className="proyectoAsignadoAsesorAsesoresPage" variant="h6">
            {asesor?.proyectoAsignado}
          </Typography>
          <Typography className="nombreAsesorAsesoresPage" variant="h6">
            ___
          </Typography>
          <Typography className="nombreAsesorAsesoresPage" variant="h6">
            Contacto
          </Typography>
          <Typography className="datosContactoAsesorAsesoresPage" variant="h6">
            <PhoneIcon className="iconCelularAsesoresPage" />
            {asesor?.celular}
          </Typography>
          <Typography className="datosContactoAsesorAsesoresPage" variant="h6">
            <MailIcon className="iconEmailAsesoresPage" />
            {asesor?.email}
          </Typography>
          <Typography className="datosContactoAsesorAsesoresPage" variant="h6">
            <a
              className="aAsesoresPage"
              href={"https://api.whatsapp.com/send?phone=" + asesor?.celular}
              target="_blank"
              rel="noreferrer"
            >
              <WhatsAppIcon className="iconWhatsAppAsesoresPage" />
              Env&iacute;ame un mensaje
            </a>
          </Typography>
        </Grid>
      </Grid>
    </Fragment>
  );
}
export default AsesorCard;
