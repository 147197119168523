import AppBarComponent from "../components/appbar.component";
import FacebookIcon from "@material-ui/icons/Facebook";
import FooterComponent from "../components/footer.component";
import Global from "../../global";
import Grid from "@material-ui/core/Grid";
import InstagramIcon from "@material-ui/icons/Instagram";
import MailIcon from "@material-ui/icons/Mail";
import PhoneIcon from "@material-ui/icons/Phone";
import RoomIcon from "@material-ui/icons/Room";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { Banner } from "../../models/banner";
import { Button } from "antd";
import { Empresa } from "../../models/empresa";
import { Form } from "antd";
import { Fragment, useContext } from "react";
import { Modal } from "antd";
import { Select } from "antd";
import { Spin } from "antd";
import { useCallback } from "react";
import { useState } from "react";
import "../../styles/pages/contactanos.page.css";
import "antd/dist/antd.css";
import { APIMail } from "../../api/api_mail";
import MailContext from "../../providers/mail-provider";

function ContactanosPage({
  empresa,
  banner,
  logoLibro,
}: {
  empresa?: Empresa;
  banner?: Banner;
  logoLibro?: Banner;
}) {
  const [spin, setSpin] = useState(false);
  const [form] = Form.useForm();
  const { Option } = Select;
  const apiMail = useContext<APIMail>(MailContext);

  const sendMail = useCallback(
    async (body: any, origen: string) => {
      await apiMail.sendMail(body, origen);
    },
    [apiMail]
  );

  const onFinish = async (body: any) => {
    setSpin(true);
    const origen: string =
      window.location.href.split("=")[
        window.location.href.split("=").length - 1
      ];

    const SibApiV3Sdk = require("sib-api-v3-typescript");
    let apiInstance = new SibApiV3Sdk.TransactionalEmailsApi();
    let apiKey = apiInstance.authentications["apiKey"];
    apiKey.apiKey =
      "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";
    let sendSmtpEmail = new SibApiV3Sdk.SendSmtpEmail();
    sendSmtpEmail.subject = body["subject"];
    sendSmtpEmail.htmlContent = `
      <html>
        <body>
          <h1>${body["subject"]}</h1>
          <p>Nombres: <b>${body["nombres"]}</b></p>
          <p>Apellidos: <b>${body["apellidos"]}</b></p>
          <p>Celular: <b>${body["celular"]}</b></p>
          <p>Email: <b>${body["email"]}</b></p>
          <p>DNI: <b>${body["dni"]}</b></p>
          <p>Proyecto: <b>${body["subject"]}</b></p>
          <p>Comentario/Mensaje: <b>${body["message"]}</b></p>
          <p>Vengo de: <b>${origen}</b></p>
        </body>
      </html>`;
    sendSmtpEmail.sender = {
      name: body["nombre"],
      email: body["email"],
    };
    sendSmtpEmail.to = [{ email: empresa?.correo, name: "HARAY INMOBILIARIA" }];
    sendSmtpEmail.replyTo = { email: body["email"], name: body["nombre"] };
    sendSmtpEmail.headers = { "Some-Custom-Name": "unique-id-1234" };
    apiInstance.sendTransacEmail(sendSmtpEmail).then(
      function (data: any) {
        Modal.success({
          title: "Inmobiliaria HARAY",
          content: "Muchas gracias por contactarnos",
        });
        sendMail(body, origen);
        form.resetFields();
      },
      function (error: any) {
        Modal.error({
          title: "Inmobiliaria HARAY",
          content: "Ocurrió un error, intétalo más tarde",
        });
      }
    );
    setSpin(false);
  };

  return (
    <Fragment>
      <Grid container>
        <Grid item={true} xl={12} lg={12} md={12} sm={12} xs={12}>
          <div
            style={{
              backgroundImage: `url(${Global.URL_IMAGENES_BANNERS}/${
                banner?.url ?? "loading.gif"
              })`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              height: "80vh",
            }}
          >
            <AppBarComponent celular={empresa?.celular}></AppBarComponent>

            <Grid container>
              <Grid
                item={true}
                xl={5}
                lg={5}
                md={5}
                sm={12}
                xs={12}
                className="gridTituloContactanosPage"
              >
                <Typography variant="h3" className="tituloContactanosPage">
                  __
                  <br />
                  Cont&aacute;ctanos
                </Typography>
              </Grid>
              <Grid
                item={true}
                xl={7}
                lg={7}
                md={7}
                sm={12}
                xs={12}
                className="gridSubtituloContactanosPage"
              >
                <Typography variant="h6" className="subtituloContactanosPage">
                  Cualquier consulta no dude en comunicarse con nosotros, con
                  mucho gusto le atenderemos.
                </Typography>
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid item={true} xl={12} lg={12} md={12} sm={12} xs={12}>
          <Grid container>
            <Grid
              item={true}
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
              className="gridHorarioContactanosPage"
            >
              <Typography variant="h4" className="tituloAzulContactanosPage">
                Ponte en contacto con nosotros
              </Typography>
              <Typography variant="h6" className="horarioContactanosPage">
                Puedes llamarnos de lunes a viernes en el horario de 8:00 a. m.
                a 5:30 p. m. al n&uacute;mero {empresa?.celular} y te
                atenderemos a la brevedad. Tambi&eacute;n puedes seguirnos en
                redes sociales
              </Typography>

              <a href={empresa?.facebook} target="_blank" rel="noreferrer">
                <FacebookIcon className="facebookIconContactanosPage" />
              </a>
              <a href={empresa?.instagram} target="_blank" rel="noreferrer">
                <InstagramIcon className="instagramIconContactanosPage" />
              </a>
            </Grid>
            <Grid
              item={true}
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
              className="gridDatosContactanosPage"
            >
              <Typography variant="h4" className="tituloAzulContactanosPage">
                Detalles de contacto
              </Typography>
              <a
                href="https://www.google.com/maps/place/HARAY+Inmobiliaria/@-8.1214979,-79.0354914,19z/data=!4m13!1m7!3m6!1s0x91ad3d70eabfc8b5:0x8bfa3f9ed62f90cb!2sSta.+Teresa+de+Jes%C3%BAs,+Trujillo+13008!3b1!8m2!3d-8.1221569!4d-79.0341902!3m4!1s0x91ad3dae97912023:0x973b22633e29499f!8m2!3d-8.1213271!4d-79.0353278"
                target="_blank"
                rel="noreferrer"
              >
                <Typography
                  variant="h6"
                  className="datosContactoContactanosPage"
                >
                  <RoomIcon className="roomIconContactanosPage" />
                  {empresa?.direccion}
                </Typography>
              </a>
              <Typography variant="h6" className="datosContactoContactanosPage">
                <PhoneIcon className="phoneIconContactanosPage" />
                {empresa?.celular}
              </Typography>
              <Typography variant="h6" className="datosContactoContactanosPage">
                <MailIcon className="mailIconContactanosPage" />
                {empresa?.correo}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item={true} xl={12} lg={12} md={12} sm={12} xs={12}>
          <Grid container className="containerContactanosPage">
            <Grid
              item={true}
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
              className="gridFormularioContactanosPage"
            >
              <Typography variant="h4" className="tituloAzulContactanosPage">
                Env&iacute;anos un mensaje
              </Typography>
              <Typography
                variant="h6"
                className="mensajeFormularioContactanosPage"
              >
                Puedes enviarnos un mensaje para que nuestros representantes de
                ventas puedan ponerse en contacto contigo a la mayor brevedad
                posible
              </Typography>

              <Form
                name="basic"
                onFinish={onFinish}
                layout="vertical"
                form={form}
                initialValues={{
                  nombres: "",
                  apellidos: "",
                  celular: "",
                  email: "",
                  dni: "",
                  subject: "",
                  message: "",
                }}
              >
                <Form.Item
                  label={
                    <Typography
                      variant="h5"
                      className="labelFormularioContactanosPage"
                    >
                      Nombres
                    </Typography>
                  }
                  name="nombres"
                  rules={[
                    {
                      required: true,
                      message: "¡Por favor ingresa tus nombres!",
                      pattern: /^[A-Za-z\s]+$/,
                    },
                  ]}
                >
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    className="inputFormularioContactanosPage"
                    size="small"
                  />
                </Form.Item>

                <Form.Item
                  label={
                    <Typography
                      variant="h5"
                      className="labelFormularioContactanosPage"
                    >
                      Apellidos
                    </Typography>
                  }
                  name="apellidos"
                  rules={[
                    {
                      required: true,
                      message: "¡Por favor ingresa tu apellidos!",
                      pattern: /^[A-Za-z\s]+$/,
                    },
                  ]}
                >
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    className="inputFormularioContactanosPage"
                    size="small"
                  />
                </Form.Item>

                <Form.Item
                  label={
                    <Typography
                      variant="h5"
                      className="labelFormularioContactanosPage"
                    >
                      Celular
                    </Typography>
                  }
                  name="celular"
                  rules={[
                    {
                      required: true,
                      message: "¡Por favor ingresa tu celular!",
                      pattern: /^[0-9]+$/,
                    },
                  ]}
                >
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    className="inputFormularioContactanosPage"
                    size="small"
                  />
                </Form.Item>

                <Form.Item
                  label={
                    <Typography
                      variant="h5"
                      className="labelFormularioContactanosPage"
                    >
                      Correo Electr&oacute;nico
                    </Typography>
                  }
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "¡Por favor ingresa tu correo!",
                      type: "email",
                    },
                  ]}
                >
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    className="inputFormularioContactanosPage"
                    size="small"
                  />
                </Form.Item>

                <Form.Item
                  label={
                    <Typography
                      variant="h5"
                      className="labelFormularioContactanosPage"
                    >
                      DNI
                    </Typography>
                  }
                  name="dni"
                  rules={[
                    {
                      required: true,
                      message: "¡Por favor ingresa tu DNI!",
                      pattern: /^[0-9]+$/,
                    },
                  ]}
                >
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    className="inputFormularioContactanosPage"
                    size="small"
                  />
                </Form.Item>

                <Form.Item
                  label={
                    <Typography
                      variant="h5"
                      className="labelFormularioContactanosPage"
                    >
                      Proyecto
                    </Typography>
                  }
                  name="subject"
                  rules={[
                    {
                      required: true,
                      message: "¡Por favor selecciona un proyecto!",
                    },
                  ]}
                >
                  <Select placeholder="Elija una opción">
                    <Option key=" Casas - Villaluz" value=" Casas - Villaluz">
                      Casas - Villaluz
                    </Option>
                    <Option key="Lotes - Casuarinas" value="Lotes - Casuarinas">
                      Lotes - Casuarinas
                    </Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  label={
                    <Typography
                      variant="h5"
                      className="labelFormularioContactanosPage"
                    >
                      Comentario o mensaje
                    </Typography>
                  }
                  name="message"
                  rules={[
                    {
                      required: true,
                      message: "¡Por favor ingresa el comentario o mensaje!",
                    },
                  ]}
                >
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    className="inputFormularioContactanosPage"
                    size="small"
                  />
                </Form.Item>
                <Form.Item>
                  {spin ? (
                    <Spin />
                  ) : (
                    <Button
                      type="default"
                      htmlType="submit"
                      shape="round"
                      className="btn_enviar"
                    >
                      Enviar
                    </Button>
                  )}
                </Form.Item>
              </Form>
            </Grid>
            <Grid item={true} xl={6} lg={6} md={6} sm={12} xs={12}>
              <iframe
                title="Inmobiliaria HARAY"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d830.3441542741517!2d-79.0354366952137!3d-8.121356908546892!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x91ad3dae97912023%3A0x973b22633e29499f!2sHARAY%20Inmobiliaria!5e0!3m2!1ses-419!2spe!4v1628870482598!5m2!1ses-419!2spe"
                width="100%"
                height="100%"
                loading="lazy"
                className="mapaContactanosPage"
              ></iframe>
            </Grid>
          </Grid>
        </Grid>
        <Grid item={true} xl={12} lg={12} md={12} sm={12} xs={12}>
          <FooterComponent
            empresa={empresa}
            libroReclamaciones={logoLibro}
          ></FooterComponent>
        </Grid>
      </Grid>
    </Fragment>
  );
}
export default ContactanosPage;
