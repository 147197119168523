import { Banner } from "../../models/banner";
import { Empresa } from "../../models/empresa";
import { Fragment } from "react";
import { Promocion } from "../../models/promocion";
import { Proyecto } from "../../models/proyecto";
import AppBarComponent from "../components/appbar.component";
import AsesoresComponent from "../components/asesores.component";
import FooterComponent from "../components/footer.component";
import Global from "../../global";
import Grid from "@material-ui/core/Grid";
import InicioComponent from "../components/inicio.component";
import NosotrosComponent from "../components/nosotros.component";
import PromocionesInicioComponent from "../components/promocionesInicio.component";
import ProyectosComponent from "../components/proyectos.component";

function InicioPage({
  empresa,
  banner,
  familia1,
  familia2,
  logoLibro,
  listaProyectosApi,
  listaPromocionesApi,
}: {
  empresa?: Empresa;
  banner?: Banner;
  familia1?: Banner;
  familia2?: Banner;
  logoLibro?: Banner;
  listaProyectosApi?: Array<Proyecto>;
  listaPromocionesApi?: Array<Promocion>;
}) {
  return (
    <Fragment>
      <Grid container>
        <Grid item={true} xl={12} lg={12} md={12} sm={12} xs={12}>
          <div
            style={{
              backgroundImage: `url(${Global.URL_IMAGENES_BANNERS}/${
                banner?.url ?? "loading.gif"
              })`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          >
            <AppBarComponent celular={empresa?.celular}></AppBarComponent>
            <InicioComponent empresa={empresa}></InicioComponent>
          </div>
        </Grid>
        <Grid item={true} xl={12} lg={12} md={12} sm={12} xs={12}>
          <ProyectosComponent
            proyectos={listaProyectosApi}
          ></ProyectosComponent>
        </Grid>
        <Grid item={true} xl={12} lg={12} md={12} sm={12} xs={12}>
          <NosotrosComponent
            familia1={familia1}
            familia2={familia2}
          ></NosotrosComponent>
        </Grid>
        <Grid item={true} xl={12} lg={12} md={12} sm={12} xs={12}>
          <AsesoresComponent empresa={empresa!}></AsesoresComponent>
        </Grid>
        <Grid item={true} xl={12} lg={12} md={12} sm={12} xs={12}>
          <PromocionesInicioComponent
            promocionA={
              listaPromocionesApi ? listaPromocionesApi[0] : new Promocion({})
            }
            promocionB={
              listaPromocionesApi ? listaPromocionesApi[1] : new Promocion({})
            }
          ></PromocionesInicioComponent>
        </Grid>
        <Grid item={true} xl={12} lg={12} md={12} sm={12} xs={12}>
          <FooterComponent
            empresa={empresa}
            libroReclamaciones={logoLibro}
          ></FooterComponent>
        </Grid>
      </Grid>
    </Fragment>
  );
}
export default InicioPage;
