import "../../styles/components/appbar.component.css";
import { createStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import logo from "../../assets/logo.png";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreIcon from "@material-ui/icons/MoreVert";
import React from "react";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sectionDesktop: {
      display: "none",
      [theme.breakpoints.up("md")]: {
        display: "flex",
      },
    },
    sectionMobile: {
      display: "flex",
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
  })
);

function AppBarComponent({ celular }: { celular?: String }) {
  const classes = useStyles();
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    React.useState<null | HTMLElement>(null);

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <Typography className="item" variant="h6">
          <a href="/" className="aMovil">
            Inicio
          </a>
        </Typography>
      </MenuItem>
      <MenuItem>
        <Typography className="item" variant="h6">
          <a href="/nosotros" className="aMovil">
            Nosotros
          </a>
        </Typography>
      </MenuItem>
      <MenuItem>
        <Typography className="item" variant="h6">
          <a href="/asesores" className="aMovil">
            Asesores
          </a>
        </Typography>
      </MenuItem>
      <MenuItem>
        <Typography className="item" variant="h6">
          <a href="/proyectos" className="aMovil">
            Proyectos
          </a>
        </Typography>
      </MenuItem>
      <MenuItem>
        <Typography className="item" variant="h6">
          <a href="/contactanos" className="aMovil">
            Cont&aacute;ctanos
          </a>
        </Typography>
      </MenuItem>
    </Menu>
  );

  return (
    <div className="grow">
      <AppBar position="static" className="appbar">
        <Toolbar>
          <img
            src={logo}
            alt="Inmobiliaria HARAY"
            title="Inmobiliaria HARAY"
            className="logo"
          />
          <div className="grow" />
          <div className={classes.sectionDesktop}>
            <Typography className="item" variant="h6">
              <a href="/">Inicio</a>
            </Typography>
            <Typography className="item" variant="h6">
              <a href="/nosotros">Nosotros</a>
            </Typography>
            <Typography className="item" variant="h6">
              <a href="/asesores">Asesores</a>
            </Typography>
            <Typography className="item" variant="h6">
              <a href="/proyectos">Proyectos</a>
            </Typography>
            <Typography className="item" variant="h6">
              <a href="/contactanos">Cont&aacute;ctanos</a>
            </Typography>
            <Typography className="number" variant="h6">
              {celular}
            </Typography>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
    </div>
  );
}
export default AppBarComponent;
