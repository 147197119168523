import { APIResponse } from "../models/api_response";
import { Empresa } from "../models/empresa";
import { API } from "./api";

export class APIEmpresa extends API {
  constructor() {
    super("/datosEmpresa/");
  }

  async getData(): Promise<APIResponse<Empresa>> {
    const result = await this.get("", {
      body: {},
    });
    if (result.exception.message !== "") {
      return result;
    }
    result.data = new Empresa(result.data);
    return result;
  }
}
