import "../../styles/components/promocionesProyecto.component.css";
import { Fragment } from "react";
import { Grid } from "@material-ui/core";
import { Promocion } from "../../models/promocion";
import { Typography } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import Carousel from "react-material-ui-carousel";
import Global from "../../global";

function PromocionesProyectosComponent({
  promociones,
}: {
  promociones?: Array<Promocion>;
}) {
  return promociones!.length > 0 ? (
    <Fragment>
      <Grid container className="gridPromocionesPromocionesProyectoComponent">
        <Grid item={true} xl={12} lg={12} md={12} sm={12} xs={12}>
          <Typography
            variant="h4"
            className="tituloPromocionesProyectoComponent"
          >
            Promociones
          </Typography>
        </Grid>
        <Carousel
          navButtonsAlwaysVisible
          className="carousel"
          NextIcon={<ArrowForwardIcon />}
          PrevIcon={<ArrowBackIcon />}
          indicators={false}
        >
          {promociones?.map((promocion, index) => (
            <div
              key={index}
              className="divImagenesPromocionPromocionesProyectoComponent"
            >
              <img
                src={`${Global.URL_IMAGENES_PROMOCIONES}/${
                  promocion?.imagen ?? "loading.gif"
                }`}
                alt="Inmobiliaria HARAY"
                title="Inmobiliaria HARAY"
                className="imagenPromocionPromocionesProyectoComponent"
              />
            </div>
          ))}
        </Carousel>
      </Grid>
    </Fragment>
  ) : (
    <div></div>
  );
}
export default PromocionesProyectosComponent;
