import "../../styles/pages/asesores.page.css";
import { Asesor } from "../../models/asesor";
import { Banner } from "../../models/banner";
import { Empresa } from "../../models/empresa";
import { Fragment } from "react";
import AppBarComponent from "../components/appbar.component";
import AsesorCard from "../util/asesor_card";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import FooterComponent from "../components/footer.component";
import Global from "../../global";
import Grid from "@material-ui/core/Grid";
import iconoManoPersonas from "../../assets/iconoManoPersonas.png";
import iconoManos from "../../assets/iconoManos.png";
import iconoPersona from "../../assets/iconoPersona.png";
import Typography from "@material-ui/core/Typography";

function AsesoresPage({
  empresa,
  banner,
  bannerFamilia,
  logoLibro,
  listaAsesoresApi,
}: {
  empresa?: Empresa;
  banner?: Banner;
  bannerFamilia?: Banner;
  logoLibro?: Banner;
  listaAsesoresApi?: Array<Asesor>;
}) {
  const listaAsesores = listaAsesoresApi?.map((asesor, index) => (
    <AsesorCard asesor={asesor} key={index}></AsesorCard>
  ));
  return (
    <Fragment>
      <Grid container>
        <Grid item={true} xl={12} lg={12} md={12} sm={12} xs={12}>
          <div
            style={{
              backgroundImage: `url(${Global.URL_IMAGENES_BANNERS}/${banner?.url ?? "loading.gif"})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          >
            <AppBarComponent celular={empresa?.celular}></AppBarComponent>
            <Grid container>
              <Grid
                item={true}
                xl={5}
                lg={5}
                md={5}
                sm={12}
                xs={12}
                className="gridTituloAsesoresPage"
              >
                <Typography className="tituloAsesoresPage" variant="h3">
                  __
                  <br />
                  Nuestros Asesores
                </Typography>
              </Grid>
              <Grid
                item={true}
                xl={7}
                lg={7}
                md={7}
                sm={12}
                xs={12}
                className="gridSubtituloAsesoresPage"
              >
                <Typography className="subtituloAsesoresPage" variant="h6">
                  Contamos con un equipo de ventas altamente calificado a tu
                  disposici&oacute;n, qui&eacute;nes te guiar&aacute;n en el
                  proceso de adquirir la casa de tus sueños.
                </Typography>
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid item={true} xl={12} lg={12} md={12} sm={12} xs={12}>
          <Grid container>
            <Grid
              item={true}
              xl={4}
              lg={4}
              md={4}
              sm={12}
              xs={12}
              className="gridMotivacionTituloAsesoresPage"
            >
              <Typography className="motivacionTituloAsesoresPage" variant="h4">
                ___ <br />
                Nuestra motivaci&oacute;n
              </Typography>
            </Grid>
            <Grid
              item={true}
              xl={8}
              lg={8}
              md={8}
              sm={12}
              xs={12}
              className="gridMotivacionAsesoresPage"
            >
              <Typography className="motivacionAsesoresPage" variant="h6">
                Como asesores somos los responsables en contribuir con el sueño
                de las familias peruanas de tener una casa propia. Gracias a
                nuestro esfuerzo, mejoraremos la calidad de vida de miles de
                familias permitiéndoles acceder a una vivienda digna.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item={true} xl={12} lg={12} md={12} sm={12} xs={12}>
          <Grid container className="containerIconsAsesoresPage">
            <Grid item={true} xl={4} lg={4} md={4} sm={12} xs={12}>
              <Card className="cardAsesoresPage">
                <CardHeader
                  avatar={
                    <img
                      alt="Responsabilidad Social"
                      title="Responsabilidad Social"
                      src={iconoManos}
                      className="iconAsesoresPage"
                    />
                  }
                  title={
                    <Typography className="nombreIconAsesoresPage" variant="h5">
                      Responsabilidad Social
                    </Typography>
                  }
                  className="cardHeaderAsesoresPage"
                />
              </Card>
            </Grid>
            <Grid item={true} xl={4} lg={4} md={4} sm={12} xs={12}>
              <Card className="cardAsesoresPage">
                <CardHeader
                  avatar={
                    <img
                      alt="Respeto al cliente"
                      title="Respeto al cliente"
                      src={iconoManoPersonas}
                      className="iconAsesoresPage"
                    />
                  }
                  title={
                    <Typography className="nombreIconAsesoresPage" variant="h5">
                      Respeto al cliente
                    </Typography>
                  }
                  className="cardHeaderAsesoresPage"
                />
              </Card>
            </Grid>
            <Grid item={true} xl={4} lg={4} md={4} sm={12} xs={12}>
              <Card className="cardAsesoresPage">
                <CardHeader
                  avatar={
                    <img
                      alt="Honestidad"
                      title="Honestidad"
                      src={iconoPersona}
                      className="iconAsesoresPage"
                    />
                  }
                  title={
                    <Typography className="nombreIconAsesoresPage" variant="h5">
                      Honestidad
                    </Typography>
                  }
                  className="cardHeaderAsesoresPage"
                />
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid item={true} xl={12} lg={12} md={12} sm={12} xs={12}>
          {listaAsesores}
        </Grid>
        <Grid item={true} xl={12} lg={12} md={12} sm={12} xs={12}>
          <Grid container className="containerFinalAsesoresPage">
            <Grid
              item={true}
              xl={8}
              lg={8}
              md={8}
              sm={12}
              xs={12}
              className="gridTextoFinalAsesoresPage"
            >
              <Typography className="textoFinalAsesoresPage" variant="h5">
                Brindamos asesoria y facilidades a nuestros clientes para
                cumplir sus sueños, además de poseer un extenso abanico de
                posibilidades para llegar a cumplir tus sueños.
                <br />
                ___
              </Typography>
            </Grid>
            <Grid
              item={true}
              xl={4}
              lg={4}
              md={4}
              sm={12}
              xs={12}
              className="containerImageFinalAsesoresPage"
            >
              <img
                src={`${Global.URL_IMAGENES_BANNERS}/${bannerFamilia?.url ?? "loading.gif"}`}
                alt="Inmobiliaria HARAY"
                title="Inmobiliaria HARAY"
                className="imageFinalAsesoresPage"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item={true} xl={12} lg={12} md={12} sm={12} xs={12}>
          <FooterComponent
            empresa={empresa}
            libroReclamaciones={logoLibro}
          ></FooterComponent>
        </Grid>
      </Grid>
    </Fragment>
  );
}
export default AsesoresPage;
