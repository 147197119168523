import "../../styles/components/asesores.component.css";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListAltIcon from "@material-ui/icons/ListAlt";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import React, { Fragment } from "react";
import ReactPlayer from "react-player";
import Typography from "@material-ui/core/Typography";
import { Empresa } from "../../models/empresa";

function AsesoresComponent({ empresa }: { empresa: Empresa }) {
  const listaBeneficios = [
    "Casa propia",
    "Tanque elevado",
    "Parques",
    "Pistas",
  ].map((beneficio, index) => (
    <ListItem key={index}>
      <ListItemAvatar style={{ textAlign: "center" }}>
        <ListAltIcon style={{ color: "rgb(255 170 0)" }} />
      </ListItemAvatar>
      <ListItemText
        primary={
          <Typography className="beneficioAsesoresComponent" variant="h6">
            {beneficio}
          </Typography>
        }
      />
    </ListItem>
  ));

  const listaBeneficios2 = [
    "Veredas",
    "Instalaciones eléctricas",
    "Instalaciones de agua y desagüe",
  ].map((beneficio, index) => (
    <ListItem key={index}>
      <ListItemAvatar style={{ textAlign: "center" }}>
        <ListAltIcon style={{ color: "rgb(255 170 0)" }} />
      </ListItemAvatar>
      <ListItemText
        primary={
          <Typography className="beneficioAsesoresComponent" variant="h6">
            {beneficio}
          </Typography>
        }
      />
    </ListItem>
  ));

  return (
    <Fragment>
      <Grid container className="containerAsesoresComponent">
        <Grid item={true} xl={12} lg={12} md={12} sm={12} xs={12}>
          <Typography className="subtituloAsesoresComponent" variant="h6">
            Inmobiliaria <br />
            ___
          </Typography>
        </Grid>
        <Grid item={true} xl={12} lg={12} md={12} sm={12} xs={12}>
          <Typography className="tituloAsesoresComponent" variant="h4">
            Asesores
          </Typography>
        </Grid>
        <Grid
          item={true}
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{ textAlign: "center" }}
        >
          <Button variant="contained" className="btn_amarillo" href="/asesores">
            Ver m&aacute;s <ArrowRightIcon />
          </Button>
        </Grid>
        <Grid
          item={true}
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{ textAlign: "center" }}
        >
          <iframe
            title="Villa Luz I"
            src="https://rodpalybaro.com/villaluzi/"
            allowFullScreen
            className="kuula"
          ></iframe>
          <ReactPlayer
            url={empresa.youtube}
            className="videoAsesoresComponent"
          />
        </Grid>
        <Grid
          item={true}
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{ textAlign: "center" }}
        >
          <Grid container>
            <Grid
              item={true}
              xl={4}
              lg={4}
              md={4}
              sm={12}
              xs={12}
              style={{ textAlign: "center" }}
            >
              <Typography className="tituloAsesoresComponent" variant="h4">
                Beneficios para <br /> nuestros clientes
              </Typography>
            </Grid>
            <Grid
              item={true}
              xl={4}
              lg={4}
              md={4}
              sm={12}
              xs={12}
              style={{ textAlign: "center" }}
            >
              <List>{listaBeneficios}</List>
            </Grid>
            <Grid
              item={true}
              xl={4}
              lg={4}
              md={4}
              sm={12}
              xs={12}
              style={{ textAlign: "center" }}
            >
              <List>{listaBeneficios2}</List>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
}
export default AsesoresComponent;
