import { APIResponse } from "../models/api_response";
import { Asesor } from "../models/asesor";
import { API } from "./api";

export class APIAsesor extends API {
  constructor() {
    super("/asesores/");
  }

  async getData(): Promise<APIResponse<Array<Asesor>>> {
    const result = await this.get("", {
      body: {},
    });
    if (result.exception.message !== "") {
      return result;
    }
    const asesors: Asesor[] = [];
    result.data?.forEach((asesor: Asesor) => {
      asesors.push(new Asesor(asesor));
    });
    result.data = asesors;
    return result as any;
  }
}
