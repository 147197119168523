import { Fragment } from "react";
import { Proyecto } from "../../models/proyecto";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Global from "../../global";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";

function ProyectoCardMedium({ proyecto }: { proyecto?: Proyecto }) {
  return (
    <Fragment>
      <Grid
        key={proyecto?.id}
        item={true}
        xl={6}
        lg={6}
        md={6}
        sm={12}
        xs={12}
        style={{ textAlign: "center", padding: "20px 80px" }}
      >
        <Avatar
          alt={proyecto?.nombre}
          src={`${Global.URL_FOTOS_PROYECTOS}/${proyecto?.foto}`}
          className="avatarNosotrosPage"
        />
        <Typography className="nombreProyectoNosotrosPage" variant="h6">
          {proyecto?.nombre}
        </Typography>
        <Typography className="descripcionProyectoNosotrosPage" variant="h6">
          {proyecto?.descripcion}
        </Typography>
        <a
          className="aNosotrosPage"
          href={proyecto?.facebook}
          target="_blank"
          rel="noreferrer"
        >
          <FacebookIcon className="facebookIconNosotrosPage" />
        </a>
        <a
          className="aNosotrosPage"
          href={proyecto?.instagram}
          target="_blank"
          rel="noreferrer"
        >
          <InstagramIcon className="instagramIconNosotrosPage" />
        </a>
      </Grid>
    </Fragment>
  );
}
export default ProyectoCardMedium;
