import { APIResponse } from "../models/api_response";
import { Promocion } from "../models/promocion";
import { API } from "./api";

export class APIPromocion extends API {
  constructor() {
    super("/promociones/");
  }

  async getData(): Promise<APIResponse<Array<Promocion>>> {
    const result = await this.get("", {
      body: {},
    });
    if (result.exception.message !== "") {
      return result;
    }
    const promociones: Promocion[] = [];
    result.data?.forEach((promocion: Promocion) => {
      promociones.push(new Promocion(promocion));
    });
    result.data = promociones;
    return result as any;
  }
}
