import { Fragment } from "react";
import { Proyecto } from "../../models/proyecto";
import Avatar from "@material-ui/core/Avatar";
import FacebookIcon from "@material-ui/icons/Facebook";
import Global from "../../global";
import Grid from "@material-ui/core/Grid";
import InstagramIcon from "@material-ui/icons/Instagram";
import PhoneIcon from "@material-ui/icons/Phone";
import RoomIcon from "@material-ui/icons/Room";
import Typography from "@material-ui/core/Typography";

function ProyectoCardBig({ proyecto }: { proyecto?: Proyecto }) {
  return (
    <Fragment>
      <Grid
        key={proyecto?.id}
        item={true}
        xl={6}
        lg={6}
        md={6}
        sm={12}
        xs={12}
        className="gridProyectosProyectosPage"
      >
        <Avatar
          alt={proyecto?.nombre}
          title={proyecto?.nombre}
          src={`${Global.URL_FOTOS_PROYECTOS}/${proyecto?.foto}`}
          className="imagenProyectoProyectosPage"
        />
        <div className="divDescripcionProyecto">
          <Typography className="nombreProyectoProyectosPage" variant="h5">
            {proyecto?.nombre}
          </Typography>
          <Typography className="descripcionProyectoProyectosPage" variant="h6">
            {proyecto?.descripcion}
          </Typography>
        </div>
        <iframe
          title={proyecto?.nombre}
          src={proyecto?.ubicacion}
          width="600"
          height="450"
          loading="lazy"
          className="mapaProyectoProyectosPage"
        ></iframe>
        <Grid container>
          <Grid item={true} xl={12} lg={12} md={12} sm={12} xs={12}>
            <a href={proyecto?.facebook} target="_blank" rel="noreferrer">
              <FacebookIcon className="facebookIconProyectosPage" />
            </a>
            <a href={proyecto?.instagram} target="_blank" rel="noreferrer">
              <InstagramIcon className="instagramIconProyectosPage" />
            </a>
          </Grid>
        </Grid>
        <Typography variant="h6" className="direccionProyectoProyectosPage">
          <RoomIcon className="roomIconProyectosPage" />
          {proyecto?.direccion}
        </Typography>
        <Typography variant="h6" className="celularProyectoProyectosPage">
          <PhoneIcon className="phoneIconProyectosPage" />
          {proyecto?.celular}
        </Typography>
      </Grid>
    </Fragment>
  );
}
export default ProyectoCardBig;
