import { APIResponse } from "../models/api_response";
import { Proyecto } from "../models/proyecto";
import { API } from "./api";

export class APIProyecto extends API {
  constructor() {
    super("/proyectos/");
  }

  async getData(): Promise<APIResponse<Array<Proyecto>>> {
    const result = await this.get("", {
      body: {},
    });
    if (result.exception.message !== "") {
      return result;
    }
    const proyectos: Proyecto[] = [];
    result.data?.forEach((proyecto: Proyecto) => {
      proyectos.push(new Proyecto(proyecto));
    });
    result.data = proyectos;
    return result as any;
  }
}
